import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Strong, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "120px 0 130px 0",
	"background": "linear-gradient(0deg,--color-darkL2 0%,--color-darkL2 0%,rgba(8, 20, 28, 0.6) 97.6%),#070f4f url(https://uploads.quarkly.io/6744384d9335410018c81ed7/images/workshops.jpg?v=2024-11-25T13:59:53.762Z) 0% 0% /100% repeat scroll padding-box",
	"lg-padding": "80px 0 90px 0",
	"quarkly-title": "Stages/Steps-2"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "--headline2",
			"color": "--light",
			"children": <Strong margin="0px 0px 2rem 0px">
				Етапи нашої роботи
			</Strong>
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-template-rows": "repeat(2, 1fr)",
			"grid-gap": "40px 30px",
			"md-grid-template-columns": "1fr",
			"md-grid-template-rows": "auto",
			"md-grid-gap": "26px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"padding": "0px 70px 0px 0px",
			"lg-padding": "0px 0 0px 0px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "40px",
			"min-height": "40px",
			"background": "--color-primary",
			"align-items": "center",
			"display": "flex",
			"justify-content": "center",
			"width": "40px",
			"height": "40px",
			"border-radius": "50%"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "--lead",
			"text-align": "center",
			"children": "1"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0px 0px 25px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"border-color": "--color-light",
			"color": "--light",
			"font": "normal 500 20px/1.2 --fontFamily-sans",
			"children": "Діагностика"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--light",
			"children": <>
				Ми ретельно перевіряємо стан вашого транспорту та визначаємо всі можливі несправності.
				<br />
				<br />
			</>
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"padding": "0px 70px 0px 0px",
			"lg-padding": "0px 0 0px 0px"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "40px",
			"min-height": "40px",
			"background": "--color-primary",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center",
			"width": "40px",
			"height": "40px",
			"border-radius": "50%"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "--lead",
			"text-align": "center",
			"children": "2"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0px 0px 25px"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"border-color": "--color-light",
			"color": "--light",
			"font": "normal 500 20px/1.2 --fontFamily-sans",
			"children": "Консультація."
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--light",
			"children": "Після діагностики ми обговорюємо з вами всі деталі ремонту, включаючи терміни та вартість."
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"padding": "0px 70px 0px 0px",
			"lg-padding": "0px 0 0px 0px"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"min-width": "40px",
			"min-height": "40px",
			"background": "--color-primary",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center",
			"width": "40px",
			"height": "40px",
			"border-radius": "50%"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "--lead",
			"text-align": "center",
			"children": "3"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0px 0px 25px"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"border-color": "--color-light",
			"color": "--light",
			"font": "normal 500 20px/1.2 --fontFamily-sans",
			"children": "Ремонтні роботи."
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--light",
			"children": <>
				Наші фахівці виконують усі необхідні роботи, використовуючи якісні деталі та сучасне обладнання.
				<br />
			</>
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"padding": "0px 70px 0px 0px",
			"lg-padding": "0px 0 0px 0px"
		}
	},
	"box11": {
		"kind": "Box",
		"props": {
			"min-width": "40px",
			"min-height": "40px",
			"background": "--color-primary",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center",
			"width": "40px",
			"height": "40px",
			"border-radius": "50%"
		}
	},
	"text10": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "--lead",
			"text-align": "center",
			"children": "4"
		}
	},
	"box12": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0px 0px 25px"
		}
	},
	"text11": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"border-color": "--color-light",
			"color": "--light",
			"font": "normal 500 20px/1.2 --fontFamily-sans",
			"children": "Перевірка та тестування."
		}
	},
	"text12": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--light",
			"children": <>
				Після ремонту ми тестуємо транспорт, щоб переконатися, що він працює ідеально.
				<br />
			</>
		}
	},
	"box13": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"padding": "0px 70px 0px 0px",
			"lg-padding": "0px 0 0px 0px"
		}
	},
	"box14": {
		"kind": "Box",
		"props": {
			"min-width": "40px",
			"min-height": "40px",
			"background": "--color-primary",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center",
			"width": "40px",
			"height": "40px",
			"border-radius": "50%"
		}
	},
	"text13": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "--lead",
			"text-align": "center",
			"children": "5"
		}
	},
	"box15": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0px 0px 25px"
		}
	},
	"text14": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"border-color": "--color-light",
			"color": "--light",
			"font": "normal 500 20px/1.2 --fontFamily-sans",
			"children": "Передача транспорту."
		}
	},
	"text15": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--light",
			"children": <>
				Після ремонту ми тестуємо транспорт, щоб переконатися, що він працює ідеально.
				<br />
			</>
		}
	}
};

const Steps = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Text {...override("text1")} />
				</Box>
				<Box {...override("box3")}>
					<Text {...override("text2")} />
					<Text {...override("text3")} />
				</Box>
			</Box>
			<Box {...override("box4")}>
				<Box {...override("box5")}>
					<Text {...override("text4")} />
				</Box>
				<Box {...override("box6")}>
					<Text {...override("text5")} />
					<Text {...override("text6")} />
				</Box>
			</Box>
			<Box {...override("box7")}>
				<Box {...override("box8")}>
					<Text {...override("text7")} />
				</Box>
				<Box {...override("box9")}>
					<Text {...override("text8")} />
					<Text {...override("text9")} />
				</Box>
			</Box>
			<Box {...override("box10")}>
				<Box {...override("box11")}>
					<Text {...override("text10")} />
				</Box>
				<Box {...override("box12")}>
					<Text {...override("text11")} />
					<Text {...override("text12")} />
				</Box>
			</Box>
			<Box {...override("box13")}>
				<Box {...override("box14")}>
					<Text {...override("text13")} />
				</Box>
				<Box {...override("box15")}>
					<Text {...override("text14")} />
					<Text {...override("text15")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Steps, { ...Section,
	defaultProps,
	overrides
});
export default Steps;