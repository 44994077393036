import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Strong, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "112px 0 0px 0",
	"background": "--color-darkL2",
	"md-padding": "96px 0 0px 0",
	"sm-padding": "72px 0 0px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "100%",
			"flex-direction": "column",
			"justify-content": "center",
			"align-items": "center",
			"lg-width": "100%",
			"lg-align-items": "center",
			"lg-margin": "0px 0px 60px 0px",
			"sm-margin": "0px 0px 40px 0px",
			"margin": "0px 0px 40px 0px",
			"padding": "0px 0px 0px 0px",
			"sm-padding": "0px 0px 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 24px 0px",
			"color": "--light",
			"font": "--headline2",
			"lg-text-align": "center",
			"sm-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"text-align": "center",
			"width": "90%",
			"md-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"sm-width": "100%",
			"children": <Strong>
				Гарантії якості
			</Strong>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 32px 0px",
			"color": "#FFFFFF",
			"font": "--headline3",
			"lg-text-align": "center",
			"text-align": "center",
			"width": "80%",
			"children": "Ми впевнені в якості нашої роботи, тому надаємо гарантію на всі послуги. Якщо у вас виникли проблеми після ремонту, ми виправимо їх безкоштовно."
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "/poslugi-ta-cini",
			"padding": "12px 24px 12px 24px",
			"color": "--dark",
			"background": "--color-secondary",
			"text-decoration-line": "initial",
			"font": "--lead",
			"border-radius": "8px",
			"margin": "0px 0px 0px 0px",
			"sm-margin": "0px 0px 0px 0px",
			"sm-text-align": "center",
			"hover-transition": "background-color 0.2s linear 0s",
			"hover-background": "--color-orange",
			"transition": "background-color 0.2s linear 0s",
			"children": "Наші послуги"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "100%",
			"justify-content": "center",
			"overflow-y": "hidden",
			"overflow-x": "hidden",
			"lg-width": "100%"
		}
	}
};

const Cta2 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Link {...override("link")} />
		</Box>
		<Box {...override("box1")} />
		{children}
	</Section>;
};

Object.assign(Cta2, { ...Section,
	defaultProps,
	overrides
});
export default Cta2;